import React, { useState, useRef } from "react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import AXIOS from "../../axios-config";
import Layout from "../components/layout";
import SEO from "../components/seo";
import cogoToast from "cogo-toast";
import ReCAPTCHA from "react-google-recaptcha";

//components
import Spinner from "../components/spinner";
import ImageBackground from "../components/imagebackground";
import settings from "../../settings";

//images
const southoccbanner = `${settings.IMAGES_BASE_URL}/images/southoccc/oc-banner.jpg`;

const SouthOCCC = () => {

  const contentRef = useRef(null);
  const [desiredClass,setDesiredClass] = useState("BMW Performance Center Drive");
  const [receiveEmails, setReceiveEmails] = useState(false);
  const [isLoading,setIsLoading] = useState(false);
  const captchaRef =  useRef();
  const [captchaToken, setCaptchaToken] = useState("");

  const handleChangeSelection = (e) => {
    setDesiredClass(e.target.value)
  };

  const handleMailsPermissionChange = (e) => {
    setReceiveEmails(e.target.checked)
  };

  const scrollToContent = () => {
    if(!contentRef) return;
    contentRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const initialValues = {
    firstname: "",
    lastname: "",
    email: "",
    address: "",
    phone_number: "",
    discount_code: "21SOCCC20",
    desiredClass: "",
    receiveEmails: false,
  };

  const validationSchema = Yup.object().shape({
    firstname: Yup.string()
      .required("Please enter your first name")
      .min(1, "Must have a character")
      .max(255, "Must be shorter than 255"),
    lastname: Yup.string()
      .required("Please enter your last name")
      .min(1, "Must have a character")
      .max(255, "Must be shorter than 255"),
    address: Yup.string()
      .required("Please enter your address")
      .min(1, "Must have a character")
      .max(255, "Must be shorter than 255"),
    discount_code: Yup.string()
      .min(1, "Must have a character")
      .max(255, "Must be shorter than 255"),
    email: Yup.string()
      .email()
      .required("Please enter your email address")
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
        "Your email address is incorrect"
      ),
    phone_number: Yup.string()
      .required("Please enter your phone number")
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        "Please enter a valid phone number"
      ),
  });

  const errorHandling = (setSubmitting) => {
    setIsLoading(false);
    setSubmitting(false);
    captchaRef.current.reset();
    setCaptchaToken("");
    window.grecaptcha.reset();
  }

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    values["desired_class"] = desiredClass;
    values["receive_emails"] = receiveEmails;
    const headers = {
      'captchaToken': captchaToken
    };
    if (!captchaToken) {
      cogoToast.error("You must verify the capctcha", {
        position: "top-center"
      });
      errorHandling(setSubmitting);
      return
    }

    setIsLoading(true);
    setSubmitting(true);

    AXIOS.post("/api/southoccc-form", values,{headers})
      .then(() => {
        errorHandling(setSubmitting);
        resetForm();
        cogoToast.success("Your form has been submitted successfully", {
          position: "top-center",
        });
      })
      .catch((err) => {
        errorHandling(setSubmitting);
        cogoToast.error("Your form wasn't submitted", {
          position: "top-center",
        });
      });
  };

    return (
      <Layout>
        <SEO
          title="BMW Performance Driving School | Group & Corporate Events"
          description="Spend the day at the BMW Performance Center with rooms to conduct team meetings, enjoy world-class cuisine and of course, get plenty of track time!"
          keywords="Team building activities"
        />
        <ImageBackground
          imageDesktop={southoccbanner}
          imageMobile={southoccbanner}
          imageAlt="BMW Touring Private Top Banner"
          scrollTo={scrollToContent}
        />
        <section className="getstarted getstarted-white" ref={contentRef}>
          <div className="container">
            <div className="getstarted__content">
              <h3>GET 20% OFF ANY SCHOOL, NOW THROUGH DECEMBER 31, 2021.*</h3>
              <p>
                Please fill out the information below to claim your South Orange
                County Cars and Coffee
                <br /> discount. A school representative will reach out to you
                to confirm class and date selection.
              </p>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ errors, touched, isSubmitting }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="input">
                      <div className="form__group">
                        <div className="input__group input">
                          <label htmlFor="desired_class">
                            <b>All fields mandatory</b>
                            <br />
                            Select your desired class
                          </label>
                          <Field
                            as="select"
                            name="desired_class"
                            value={desiredClass}
                            onChange={handleChangeSelection}
                          >
                            <option value="BMW Performance Center Drive">
                              BMW Performance Center Drive
                            </option>
                            <option value="BMW One Day School">
                              BMW One Day School
                            </option>
                            <option value="BMW One Day Teen School">
                              BMW One Day Teen School
                            </option>
                            <option value="BMW Track Meet">
                              BMW Track Meet
                            </option>
                            <option value="BMW Two Day School">
                              BMW Two Day School
                            </option>
                            <option value="BMW Two Day Teen School">
                              BMW Two Day Teen School
                            </option>
                            <option value="Two Day M School">
                              Two Day M School
                            </option>
                            <option value="Advanced M School">
                              Advanced M School
                            </option>
                            <option value="M Track Drive">M Track Drive</option>
                            <option value="One Day M School">
                              One Day M School
                            </option>
                          </Field>
                          <span className="input-feedback">&nbsp;</span>
                        </div>
                      </div>
                      <div className="form__group">
                        <div className="input__group">
                          <label htmlFor="firstname">First Name</label>
                          <Field
                            type="text"
                            name="firstname"
                            className={
                              touched.firstname && errors.firstname
                                ? "has-error"
                                : null
                            }
                          />
                          {errors.firstname && touched.firstname ? (
                            <span className="input-feedback">
                              {errors.firstname}
                            </span>
                          ) : (
                            <span className="input-feedback">&nbsp;</span>
                          )}
                        </div>
                        <div className="input__group">
                          <label htmlFor="lastname">Last Name</label>
                          <Field
                            type="text"
                            name="lastname"
                            className={
                              touched.lastname && errors.lastname
                                ? "has-error"
                                : null
                            }
                          />
                          {errors.lastname && touched.lastname ? (
                            <span className="input-feedback">
                              {errors.lastname}
                            </span>
                          ) : (
                            <span className="input-feedback">&nbsp;</span>
                          )}
                        </div>
                      </div>
                      <div className="input__group">
                        <label htmlFor="address">Address</label>
                        <Field
                          type="text"
                          name="address"
                          className={
                            touched.address && errors.address
                              ? "has-error"
                              : null
                          }
                        />
                        {errors.address && touched.address ? (
                          <span className="input-feedback">
                            {errors.address}
                          </span>
                        ) : (
                          <span className="input-feedback">&nbsp;</span>
                        )}
                      </div>
                      <div className="form__group">
                        <div className="input__group">
                          <label htmlFor="phone_number">
                            Phone number to best reach you
                          </label>
                          <Field
                            type="tel"
                            name="phone_number"
                            className={
                              touched.phone_number && errors.phone_number
                                ? "has-error"
                                : null
                            }
                          />
                          {errors.phone_number && touched.phone_number ? (
                            <span className="input-feedback">
                              {errors.phone_number}
                            </span>
                          ) : (
                            <span className="input-feedback">&nbsp;</span>
                          )}
                        </div>
                        <div className="input__group">
                          <label htmlFor="email">Email address</label>
                          <Field
                            type="text"
                            name="email"
                            className={
                              touched.email && errors.email ? "has-error" : null
                            }
                          />
                          {errors.email && touched.email ? (
                            <span className="input-feedback">
                              {errors.email}
                            </span>
                          ) : (
                            <span className="input-feedback">&nbsp;</span>
                          )}
                        </div>
                      </div>
                      <div className="form__group">
                        <div className="input__group">
                          <label htmlFor="eventType">
                            South Orange County Cars and Coffee discount code
                          </label>
                          <Field
                            type="text"
                            name="discount_code"
                            className={
                              touched.discount_code && errors.discount_code
                                ? "has-error"
                                : null
                            }
                          />

                          {errors.discount_code && touched.discount_code ? (
                            <span className="input-feedback">
                              {errors.discount_code}
                            </span>
                          ) : (
                            <span className="input-feedback">&nbsp;</span>
                          )}
                        </div>
                      </div>
                      <div className="checkbox">
                        <div className="checkbox__group">
                          <Field
                            type="checkbox"
                            name="receive_emails"
                            value={receiveEmails}
                            checked={receiveEmails}
                            onChange={handleMailsPermissionChange}
                          />
                          <label htmlFor="receive_emails">
                            I provide BMW with permission to send emails to me
                            regarding news and special
                            <br /> offers about group event programs regarding
                            the Performance Center.
                          </label>
                        </div>
                        {errors.receiveEmails && touched.receiveEmails ? (
                          <span className="input-feedback">
                            {errors.receiveEmails}
                          </span>
                        ) : (
                          <span className="input-feedback">&nbsp;</span>
                        )}
                      </div>
                    </div>
                    <ReCAPTCHA sitekey={settings.REACT_APP_SITE_KEY} ref={captchaRef} onChange={val => setCaptchaToken(val)} onExpired={e => setCaptchaToken("")}/>
                    <button
                      className="btn btn-blue"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {isLoading ? <Spinner /> : "Submit"}
                    </button>
                  </form>
                )}
              </Formik>
              <br />
              <h6 style={{ fontSize: "12px" }}>
                *Valid September 4th - December 31st, 2021. Excludes M4 GT4
                Experience, Race licensing and Private Coaching.
              </h6>
            </div>
          </div>
        </section>
      </Layout>
    );
}

export default SouthOCCC;
